<template>
  <div class="container mypage withTop">
    <section class="contents Rgt detail" v-if="farmdata.length > 0">
      <div class="buttonWrap">
        <button class="point" @click="handleMove()">수정</button>
        <button class="line" @click="handleDelete()">삭제</button>
      </div>
      <p class="bold">농가현황</p>

      <article class="box">
        <p>농장 명칭</p>
        <p>{{ farmdata[0].farmName ? farmdata[0].farmName : "-" }}</p>
        <p>농장 주소</p>
        <p>{{ farmdata[0].address ? farmdata[0].address : "-" }}</p>
        <p>농장주 연령</p>
        <p>{{ farmdata[0].ages ? getAge(farmdata[0].ages) : "-" }}세</p>
        <p>농장주 경력</p>
        <p>{{ farmdata[0].career ? farmdata[0].career : "-" }}</p>
        <p>재배면적(㎡)</p>
        <p>{{ farmdata[0].area ? farmdata[0].area : "-" }}</p>
        <p>재배품목</p>
        <p>{{ farmdata[0].crops ? farmdata[0].crops : "-" }}</p>
        <p>수확일자</p>
        <p>{{ farmdata[0].hvstDay ? moment(farmdata[0].hvstDay).format("YYYY-MM-DD") : "-" }}</p>
      </article>

      <p class="bold">환경제어 프로그램 현황</p>
      <article class="box">
        <p>PC 운영체계</p>
        <p>{{ farmdata[0].os ? farmdata[0].os : "-" }}</p>
        <p>모니터(인치)</p>
        <p>{{ farmdata[0].monitorSize ? farmdata[0].monitorSize : "-" }}</p>
        <p>환경제어 프로그램명</p>
        <p>
          {{ farmdata[0].envrnCtrlProgram ? farmdata[0].envrnCtrlProgram : "-" }}
        </p>
        <div class="flexB">
          <p>UPS 설치여부</p>
          <el-switch disabled v-model="farmdata[0].ups"> </el-switch>
        </div>
        <p>PC 설치위치</p>
        <p>{{ farmdata[0].pcLocation ? farmdata[0].pcLocation : "-" }}</p>
        <div class="flexB">
          <p>인터넷 설치여부</p>
          <el-switch disabled v-model="farmdata[0].internet"> </el-switch>
        </div>
      </article>

      <p class="bold">시설현황</p>
      <article class="box">
        <p>하우스 유형</p>
        <el-checkbox-group v-model="farmdata[0].houseType">
          <el-checkbox label="단동" disabled></el-checkbox>
          <el-checkbox label="연동" disabled></el-checkbox>
        </el-checkbox-group>
        <p>하우스 설치방향</p>
        <el-radio v-model="farmdata[0].houseDirection" disabled label="동서">동서</el-radio>
        <el-radio v-model="farmdata[0].houseDirection" disabled label="남북">남북</el-radio>
        <p>하우스 피복필름 종류</p>
        <el-radio v-model="farmdata[0].houseCover" disabled label="PO">PO</el-radio>
        <el-radio v-model="farmdata[0].houseCover" disabled label="PE">PE</el-radio>
        <p>하우스 파이프 길이(m)</p>
        <p>
          {{ farmdata[0].housePipeLength ? farmdata[0].housePipeLength : "-" }}
        </p>
        <p>하우스 높이(m)</p>
        <p>{{ farmdata[0].houseHeight ? farmdata[0].houseHeight : "-" }}</p>
        <p>하우스 폭(m)</p>
        <p>{{ farmdata[0].houseWidth ? farmdata[0].houseWidth : "-" }}</p>
        <p>하우스 길이(m)</p>
        <p>{{ farmdata[0].houseLength ? farmdata[0].houseLength : "-" }}</p>
        <p>보온자재 종류</p>
        <p>{{ houseInsulation ? houseInsulation : "-" }}</p>
        <p>난방방법</p>
        <el-radio v-model="farmdata[0].houseHeating" disabled label="열풍">열풍</el-radio>
        <el-radio v-model="farmdata[0].houseHeating" disabled label="수막">수막</el-radio>
        <p>환기방법</p>
        <el-checkbox-group v-model="farmdata[0].houseVentilation">
          <el-checkbox disabled label="출입구"></el-checkbox>
          <el-checkbox disabled label="측창"></el-checkbox>
          <el-checkbox disabled label="천창"></el-checkbox>
          <el-checkbox disabled label="고정팬"></el-checkbox>
          <el-checkbox disabled label="유동팬"></el-checkbox>
        </el-checkbox-group>
        <p>관수방법</p>
        <el-radio disabled v-model="farmdata[0].houseIrrigation" label="점적">점적</el-radio>
        <el-radio disabled v-model="farmdata[0].houseIrrigation" label="스프링클러">스프링클러</el-radio>
      </article>

      <p class="bold">외부측정장치 설치현황</p>
      <article class="box">
        <div class="flexB">
          <p>온도센서</p>
          <el-switch disabled v-model="farmdata[0].outsideTempSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>풍향센서</p>
          <el-switch disabled v-model="farmdata[0].windDirectionSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>풍속센서</p>
          <el-switch disabled v-model="farmdata[0].windSpeedSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>일사량센서</p>
          <el-switch disabled v-model="farmdata[0].insolationSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>감우센서</p>
          <el-switch disabled v-model="farmdata[0].rainfallSensor"> </el-switch>
        </div>
      </article>

      <p class="bold">내부측정장치 설치현황</p>
      <article class="box">
        <div class="flexB">
          <p>온도센서</p>
          <el-switch disabled v-model="farmdata[0].insideTempSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>습도센서</p>
          <el-switch disabled v-model="farmdata[0].humiditySensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>CO2센서</p>
          <el-switch disabled v-model="farmdata[0].co2Sensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>광량센서</p>
          <el-switch disabled v-model="farmdata[0].lightSensor"> </el-switch>
        </div>
        <div class="flexB">
          <p>지온센서</p>
          <el-switch disabled v-model="farmdata[0].soilTempSensor"> </el-switch>
        </div>
      </article>

      <p class="bold">제어</p>
      <article class="box">
        <div class="flexB">
          <p>일중천장</p>
          <el-switch disabled v-model="farmdata[0].ceilingWndw"> </el-switch>
        </div>
        <div class="flexB">
          <p>이중천장</p>
          <el-switch disabled v-model="farmdata[0].dplcWndw"> </el-switch>
        </div>
        <div class="flexB">
          <p>측창</p>
          <el-switch disabled v-model="farmdata[0].sideWallWndw"> </el-switch>
        </div>
        <div class="flexB">
          <p>천장보온커튼</p>
          <el-switch disabled v-model="farmdata[0].cellarsHeatCurtain"> </el-switch>
        </div>
        <div class="flexB">
          <p>측면보온커튼</p>
          <el-switch disabled v-model="farmdata[0].wallHeatCurtain"> </el-switch>
        </div>
        <div class="flexB">
          <p>차광커튼</p>
          <el-switch disabled v-model="farmdata[0].shadeCurtain"> </el-switch>
        </div>
        <div class="flexB">
          <p>난방기</p>
          <el-switch disabled v-model="farmdata[0].heatMhrls"> </el-switch>
        </div>
        <div class="flexB">
          <p>CO2공급</p>
          <el-switch disabled v-model="farmdata[0].co2Supply"> </el-switch>
        </div>
        <div class="flexB">
          <p>광량제어</p>
          <el-switch disabled v-model="farmdata[0].lightControl"> </el-switch>
        </div>
        <div class="flexB">
          <p>유동팬</p>
          <el-switch disabled v-model="farmdata[0].dynmcFan"> </el-switch>
        </div>
        <div class="flexB">
          <p>관수/관비 시스템</p>
          <el-switch disabled v-model="farmdata[0].irrnFrtlzrSys"> </el-switch>
        </div>
        <div class="flexB">
          <p>양액기</p>
          <el-switch disabled v-model="farmdata[0].nutrientSlutnSys"> </el-switch>
        </div>
        <div class="flexB">
          <p>두상살수장치</p>
          <el-switch disabled v-model="farmdata[0].sprinkler"> </el-switch>
        </div>
        <div class="flexB">
          <p>훈증기</p>
          <el-switch disabled v-model="farmdata[0].fumigator"> </el-switch>
        </div>
      </article>

      <p class="bold">재배기초</p>
      <article class="box">
        <p>재배 유형</p>
        <el-checkbox-group v-model="farmdata[0].ctvtTY">
          <el-checkbox disabled label="양액재배"></el-checkbox>
          <el-checkbox disabled label="토경재배"></el-checkbox>
        </el-checkbox-group>
        <p>품종명</p>
        <p>{{ spcies ? spcies : "-" }}</p>
        <p>종묘확보방법</p>

        <el-radio disabled v-model="farmdata[0].seedling" label="자가">자가</el-radio>
        <el-radio disabled v-model="farmdata[0].seedling" label="구입">구입</el-radio>
        <p>배지종류</p>
        <p>{{ soilType ? soilType : "-" }}</p>
        <p>자가퇴비여부</p>
        <el-radio disabled v-model="farmdata[0].compost" label="자가">자가</el-radio>
        <el-radio disabled v-model="farmdata[0].compost" label="구입">구입</el-radio>
        <p>정식일</p>
        <el-date-picker disabled v-model="farmdata[0].ptngDt" type="date" placeholder="선택"> </el-date-picker>
        <p>수확시작일</p>
        <el-date-picker disabled v-model="farmdata[0].hvstBgnde" type="date" placeholder="선택"> </el-date-picker>
        <p>수확종료일</p>
        <el-date-picker disabled v-model="farmdata[0].hvstEndde" type="date" placeholder="선택"> </el-date-picker>
        <p>병해충 방제회수</p>
        <p>
          {{ farmdata[0].dbyhsCccrrncDgree ? farmdata[0].dbyhsCccrrncDgree : "-" }}
        </p>
        <p>병해충 발생정도</p>
        <p>
          {{ farmdata[0].dbyhsPrvnbeRtrvl ? farmdata[0].dbyhsPrvnbeRtrvl : "-" }}
        </p>
      </article>

      <p class="bold">토양(근권)환경</p>
      <article class="box">
        <p>공급양액 PH농도</p>
        <p>{{ farmdata[0].nutrientPH ? farmdata[0].nutrientPH : "-" }}</p>
        <p>공급양액 EC</p>
        <p>{{ farmdata[0].nutrientEC ? farmdata[0].nutrientEC : "-" }}</p>
        <p>배지PH</p>
        <p>{{ farmdata[0].mdimPH ? farmdata[0].mdimPH : "-" }}</p>
        <p>배지 EC</p>
        <p>{{ farmdata[0].mdimEC ? farmdata[0].mdimEC : "-" }}</p>
      </article>

      <p class="bold">데이터 수집현황</p>
      <article class="box">
        <div class="flexB">
          <p>환경</p>
          <el-switch disabled v-model="farmdata[0].environment"> </el-switch>
        </div>
        <div class="flexB">
          <p>관수(양액)</p>
          <el-switch disabled v-model="farmdata[0].irrigation"> </el-switch>
        </div>
        <div class="flexB">
          <p>생육</p>
          <el-switch disabled v-model="farmdata[0].growh"> </el-switch>
        </div>
        <p>생산량</p>
        <p>{{ farmdata[0].output ? farmdata[0].output : "-" }}</p>
        <p>수확일자</p>
        <el-date-picker disabled v-model="farmdata[0].hvstDay" type="date" placeholder="선택"> </el-date-picker>
      </article>

      <p class="bold">영농비용</p>
      <article class="box">
        <p>농약비</p>
        <p>{{ farmdata[0].pesticideCost ? numberFormat(farmdata[0].pesticideCost) : "-" }}원</p>
        <p>광열동력비</p>
        <p>{{ farmdata[0].photothermalCost ? numberFormat(farmdata[0].photothermalCost) : "-" }}원</p>
        <p>기타(컨설팅 등)</p>
        <p>{{ farmdata[0].etcCost ? numberFormat(farmdata[0].etcCost) : "-" }}원</p>
        <p>위탁영농비</p>
        <p>{{ farmdata[0].consignmentCost ? numberFormat(farmdata[0].consignmentCost) : "-" }}원</p>
        <p>고용노력비</p>
        <p>{{ farmdata[0].employCost ? numberFormat(farmdata[0].employCost) : "-" }}원</p>
        <p>경영비</p>
        <p>{{ farmdata[0].managementCost ? numberFormat(farmdata[0].managementCost) : "-" }}원</p>
        <p>소득</p>
        <p>{{ farmdata[0].income ? numberFormat(farmdata[0].income) : "-" }}원</p>
      </article>
    </section>
  </div>
</template>
<script>
import { fetchFarmDetails, deleteFarm } from "@/api/farm";
import { mapState } from "vuex";
import { format } from "@/mixins/format";
import moment from "moment";

export default {
  mixins: [format],

  data() {
    return {
      moment: moment,
      farmId: "",

      farmName: "",
      mainFarm: false,
      address: "",
      ages: "",
      career: "",
      area: "",
      crops: "",

      os: "",
      monitorSize: "",
      envrnCtrlProgram: "",
      pcLocation: "",

      houseType: [],
      houseDirection: "",
      houseCover: "",
      houseHeating: "",
      houseIrrigation: "",
      housePipeLength: "",
      houseHeight: "",
      houseWidth: "",
      houseLength: "",
      houseInsulation: "",
      houseVentilation: [],
      ups: false,
      internet: false,
      outsideTempSensor: false,
      windDirectionSensor: false,
      windSpeedSensor: false,
      insolationSensor: false,
      rainfallSensor: false,
      insideTempSensor: false,
      humiditySensor: false,
      co2Sensor: false,
      lightSensor: false,
      soilTempSensor: false,
      ceilingWndw: false,
      dplcWndw: false,
      sideWallWndw: false,
      cellarsHeatCurtain: false,
      wallHeatCurtain: false,
      shadeCurtain: false,
      heatMhrls: false,
      co2Supply: false,
      lightControl: false,
      dynmcFan: false,
      irrnFrtlzrSys: false,
      nutrientSlutnSys: false,
      sprinkler: false,
      fumigator: false,
      ctvtTY: [],
      spcies: "",
      seedling: "",
      soilType: "",
      compost: "",
      ptngDt: "",
      hvstBgnde: "",
      hvstEndde: "",
      dbyhsCccrrncDgree: "",
      dbyhsPrvnbeRtrvl: "",
      nutrientPH: "",
      nutrientEC: "",
      mdimPH: "",
      mdimEC: "",

      environment: false,
      irrigation: false,
      growh: false,
      output: "",
      hvstDay: "",

      pesticideCost: "",
      photothermalCost: "",
      etcCost: "",
      consignmentCost: "",
      employCost: "",
      managementCost: "",
      income: "",
      farmdata: [],
    };
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", 4);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "농장등록");
  },
  mounted() {
    if (this.$route.query.id) {
      this.farmId = this.$route.query.id;
      this.farmdata = this.farmList.filter((item) => {
        if (item._id == this.farmId) return item;
      });
    }
  },
  computed: {
    ...mapState(["farmList", "userType"]),
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFarm(this.farmId).then((res) => {
          if (res.data.status == 200) {
            this.$store.dispatch("getUserFarmList");

            this.$router.replace("/mypage/farmList");
          } else {
            this.openAlert(res.data.message);
          }
        });
      });
    },
    getFarmDetail() {
      fetchFarmDetails(this.farmId).then((res) => {
        if (res.data.status == 200) {
          this.farmer = res.data.data.farmer;
          this.farmName = res.data.data.farmName;
          this.address = res.data.data.address;
          this.ages = res.data.data.ages;
          this.career = res.data.data.career;
          this.area = res.data.data.area;
          this.crops = res.data.data.crops;
          this.os = res.data.data.os;
          this.monitorSize = res.data.data.monitorSize;
          this.envrnCtrlProgram = res.data.data.envrnCtrlProgram;
          this.pcLocation = res.data.data.pcLocation;
          this.houseType = res.data.data.houseType;
          this.houseDirection = res.data.data.houseDirection;
          this.houseCover = res.data.data.houseCover;
          this.houseHeating = res.data.data.houseHeating;
          this.houseIrrigation = res.data.data.houseIrrigation;
          this.housePipeLength = res.data.data.housePipeLength;
          this.houseHeight = res.data.data.houseHeight;
          this.houseWidth = res.data.data.houseWidth;
          this.houseLength = res.data.data.houseLength;
          this.houseInsulation = res.data.data.houseInsulation;
          this.houseVentilation = res.data.data.houseVentilation;
          this.mainFarm = res.data.data.mainFarm;
          this.ups = res.data.data.ups;
          this.internet = res.data.data.internet;
          this.outsideTempSensor = res.data.data.outsideTempSensor;
          this.windDirectionSensor = res.data.data.windDirectionSensor;
          this.windSpeedSensor = res.data.data.windSpeedSensor;
          this.insolationSensor = res.data.data.insolationSensor;
          this.rainfallSensor = res.data.data.rainfallSensor;
          this.insideTempSensor = res.data.data.insideTempSensor;
          this.humiditySensor = res.data.data.humiditySensor;
          this.co2Sensor = res.data.data.co2Sensor;
          this.lightSensor = res.data.data.lightSensor;
          this.soilTempSensor = res.data.data.soilTempSensor;
          this.ceilingWndw = res.data.data.ceilingWndw;
          this.dplcWndw = res.data.data.dplcWndw;
          this.sideWallWndw = res.data.data.sideWallWndw;
          this.cellarsHeatCurtain = res.data.data.cellarsHeatCurtain;
          this.wallHeatCurtain = res.data.data.wallHeatCurtain;
          this.shadeCurtain = res.data.data.shadeCurtain;
          this.heatMhrls = res.data.data.heatMhrls;
          this.co2Supply = res.data.data.co2Supply;
          this.lightControl = res.data.data.lightControl;
          this.dynmcFan = res.data.data.dynmcFan;
          this.irrnFrtlzrSys = res.data.data.irrnFrtlzrSys;
          this.nutrientSlutnSys = res.data.data.nutrientSlutnSys;
          this.sprinkler = res.data.data.sprinkler;
          this.fumigator = res.data.data.fumigator;
          this.ctvtTY = res.data.data.ctvtTY;
          this.spcies = res.data.data.spcies;
          this.seedling = res.data.data.seedling;
          this.soilType = res.data.data.soilType;
          this.compost = res.data.data.compost;
          this.ptngDt = res.data.data.ptngDt;
          this.hvstBgnde = res.data.data.hvstBgnde;
          this.hvstEndde = res.data.data.hvstEndde;
          this.dbyhsCccrrncDgree = res.data.data.dbyhsCccrrncDgree;
          this.dbyhsPrvnbeRtrvl = res.data.data.dbyhsPrvnbeRtrvl;
          this.nutrientPH = res.data.data.nutrientPH;
          this.nutrientEC = res.data.data.nutrientEC;
          this.mdimPH = res.data.data.mdimPH;
          this.mdimEC = res.data.data.mdimEC;
          this.environment = res.data.data.environment;
          this.irrigation = res.data.data.irrigation;
          this.growh = res.data.data.growh;
          this.output = res.data.data.output;
          this.hvstDay = res.data.data.hvstDay;
          this.pesticideCost = res.data.data.pesticideCost;
          this.photothermalCost = res.data.data.photothermalCost;
          this.etcCost = res.data.data.etcCost;
          this.consignmentCost = res.data.data.consignmentCost;
          this.employCost = res.data.data.employCost;
          this.managementCost = res.data.data.managementCost;
          this.income = res.data.data.income;
          this.createdAt = res.data.data.createdAt;
        }
      });
    },
    handleMove() {
      this.$router.push({
        name: "farmRegister",
        query: { id: this.farmId },
      });
    },
  },
};
</script>
